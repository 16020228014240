import React from 'react'

import { useQueryWithStore } from 'react-admin'

import CustomTextField from 'common/MuiCustomTextField'
import getById from 'utils/getById'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  paper: {
    padding: '15px 20px',
    marginBottom: '20px'
  },
})

const ApplicationsUserInfo = ({
  regionId,
  regNumber,
  oldRegNumber,
  checkOldRegNumber,
  privilege,
  type,
  user: {
    createdAt,
    surname,
    name,
    middleName,
    phone,
    email
  },
}) => {
  const classes = useStyles()
  const { data: regions } = useQueryWithStore({
    type: 'getList',
    resource: 'regions'
  })
  const fullName = `${surname} ${name} ${middleName}`

  const showRegNumber = checkOldRegNumber && oldRegNumber

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomTextField
            value={fullName}
            label="ФИО"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={regNumber}
            label="Регистрационный номер"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          {oldRegNumber && (
            <CustomTextField
              value={oldRegNumber}
              label="Рег. номер в программе аттестации аудитора"
              fullWidth
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomTextField
            value={phone}
            label="Телефон"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={email}
            label="Email"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomTextField
            value={regions && getById(regions, regionId).name}
            label="Место проживания"
            fullWidth
            InputLabelProps={{ focused: false, shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <CustomTextField
            value={createdAt}
            label="Дата регистрации"
            fullWidth
          />
        </Grid>
      </Grid>
      {type && (
          <Grid item xs={8}>
            <CustomTextField
                value={type.name}
                label="Вариант сертификации"
                fullWidth
            />
          </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={4}>
          {!!privilege && 'Льготный претендент'}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ApplicationsUserInfo
