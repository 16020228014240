import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useDataProvider, usePermissions } from 'react-admin'
import Typography from '@material-ui/core/Typography'

import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

import { makeStyles } from '@material-ui/core/styles'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Empty } from 'common'

const headCells = [
  { id: 'examDate', label: 'Дата', disabledSort: false },
  { id: 'examTimeStart', label: 'Время', disabledSort: false },
  { id: 'cityName', label: 'Город', disabledSort: false },
  { id: 'moduleName', label: 'Модуль', disabledSort: false },
  { id: 'registered', label: 'Зарегистрировано', disabledSort: true },
  { id: 'сount', label: '', disabledSort: true },
  { id: 'edit', label: '', disabledSort: true },
]

const useStyles = makeStyles({
  date: {
    fontWeight: 'bold',
  },
  editCell: {
    padding: 0,
  },
  textRed: {
    color: 'rgba(255, 0, 0, 0.87)',
  },
  textGray: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
})

const ExamScheduleTable = ({ levelId, perPage }) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const history = useHistory()
  const { permissions: { role } = {} } = usePermissions()
  const [data, setData] = useState([])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('examDate')
  // The usePermissions hook is optimistic: it doesn’t block rendering during the authProvider call. https://marmelab.com/react-admin/Authorization.html
  // todo Вернуться к этому вопросу
  const isAdmin = role === 'admin' || localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'manager'
  const statusId = isAdmin ? '' : ',"statusId": 2'
  const isUser = role === 'user'

  const requestData = property => dataProvider.getOne(`groups?filter={"levelId":${levelId}${statusId}}&per-page=${perPage}&sort=${order === 'asc' ? '' : '-'}${property}`)
    .then(({ data: responseData }) => {
      setData(responseData)
    })

  useEffect(() => {
    requestData()
    // eslint-disable-next-line
  }, [])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    requestData(property, isAsc)
  }

  const handleEditExam = examData => {
    const { id } = examData
    const url = isAdmin ? `/exam-creation/${id}` : `/exam-booking/${id}`

    history.push({
      pathname: url,
      state: { examData },
    })
  }

  const EnhancedTableHead = () => {
    const createSortHandler = property => event => {
      handleRequestSort(event, property)
    }

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => {
            if (headCell.id !== 'registered' && role !== 'user') {
              return (
                <TableCell
                  className={classes.textGray}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    disabled={headCell.disabledSort}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              )
            }
          })}
        </TableRow>
      </TableHead>
    )
  }

  const getRows = () => {
    const { models } = data

    return models.map(row => {
      const {
        city: { name },
        examDate,
        examTimeStart,
        id,
        countMembers,
        modules,
        moduleId
      } = row

      const [examModule] = modules.filter(item => item.id === moduleId)

      return (
        <TableRow key={id}>
          <TableCell className={classes.date}>{format(new Date(examDate.replace(/-/g, '/')), 'dd MMMM yyyy', { locale: ru })}</TableCell>
          <TableCell>{examTimeStart ? examTimeStart.slice(0, -3) : 'Не определено'}</TableCell>
          <TableCell>{name}</TableCell>
          <TableCell>{examModule.name}</TableCell>
          {role !== 'user' && (<TableCell>{countMembers}</TableCell>)}
          <TableCell className={classes.editCell}>
            <IconButton onClick={() => handleEditExam(row)}>
              <CreateIcon color="primary" />
            </IconButton>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <>
      {levelId === 1 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по местному времени. В первый день сессии можно сдать не более 3 модулей, во второй день сессии — не более 2.
        </Typography>
      )}
      {levelId === 2 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по московскому времени.
        </Typography>
      )}
      {levelId === 3 && isUser && (
        <Typography style={{ marginBottom: 10, marginLeft: 24 }}>
          * Начало экзаменов по московскому времени.
        </Typography>
      )}
      <Paper>
        {data.models && data.models.length ? (
          <TableContainer>
            <Table>
              <EnhancedTableHead />
              <TableBody>
                {getRows()}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (<Empty title="Экзамены отсутствуют" />)}
      </Paper>
    </>
  )
}

export default ExamScheduleTable
