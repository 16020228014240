
import React, { useState } from 'react'
import {
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
  AutocompleteInput
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { DatePickerForFilter } from 'common'

const useStyles = makeStyles({
  searchInput: {
    width: 610,
  },
  paper: {
    marginBottom: 20,
    padding: '14px 20px 20px',
    width: '100%',

    '& .filter-field': {
      minWidth: 210
    },

    '& div:first-child': {
      minWidth: 210
    }
  },
  button: {
    marginTop: 20,
  },
  filter: {
    alignItems: 'flex-start',
    margin: 0
  }
})

const ApplicationsFilter = props => {
  const classes = useStyles()
  const { setFilters } = props
  const [flagClearDate, setFlagClearDate] = useState()

  const onClickClear = () => {
    setFilters({})
    setFlagClearDate(Symbol(''))
  }

  return (
    <Paper className={classes.paper}>
      <Filter {...props} className={classes.filter}>
        <TextInput
          alwaysOn
          className={classes.searchInput}
          label="Поиск по ФИО, регистрационному номеру, e-mail пользователя"
          source="search"
        />
        <ReferenceInput alwaysOn label="По типу заявки" source="typeId" reference="application-types">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput alwaysOn label="По статусу" source="statusId" reference="application-statuses">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput alwaysOn label="По модулю" source="moduleId" reference="modules">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DatePickerForFilter
          alwaysOn
          source="dateFrom"
          flagClearDate={flagClearDate}
          label="От"
        />
        <DatePickerForFilter
          alwaysOn
          source="dateTo"
          flagClearDate={flagClearDate}
          label="До"
        />
        <ReferenceInput alwaysOn label="По уровню" source="levelId" reference="exam-levels">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          alwaysOn
          label="По городу"
          source="cityId"
          reference="cities"
          filterToQuery={searchText => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DatePickerForFilter
            alwaysOn
            label="По дате экзамена с"
            source="examDateFrom"
            flagClearDate={flagClearDate}
            />
        <DatePickerForFilter
            alwaysOn
            label="По дате экзамена по"
            source="examDateTo"
            flagClearDate={flagClearDate}
        />
      </Filter>
      <Button
        className={classes.button}
        color="primary"
        onClick={onClickClear}
        variant="contained"
      >
        Очистить фильтр
      </Button>
    </Paper>
  )
}

export default ApplicationsFilter
